import Image from "next/image";

type Props = {
  id: number;
  img: string;
  title: string;
  desc: string;
};

const ServesCard = ({ id, img, title, desc }: Props) => {
  return (
    <div className="bg-white md:rounded-[20px] flex flex-row lg:flex-col items-center justify-stretch text-start md:text-center lg:p-4 py-4">
      <div className={`flex justify-center items-center px-3 w-full h-full lg:flex-col gap-[13px] lg:gap-0 md:items-center md:justify-between lg:py-6 serves-border lg:rounded-[12px]`}>
        <div className="flex justify-center items-center">
          <Image
            height={100}
            width={100}
            src={img}
            alt="What rent80 serves you"
            className="lg:pb-[13px] mt-1 lg:mt-0"
          />
        </div>
        <div className="text-gray-900 lg:mt-[25px] border-b border-dashed md:border-none border-[#E5EAF6] pb-[13px] grow">
          <h3 className="font-bold text-[16px] lg:text-[20px] ">
            {title}
          </h3>
          <p className="text-[14px] lg:text-base opacity-70 mt-[4px] lg:mt-[10px]">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServesCard;
