import { useFormatter, useTranslations } from "next-intl";
import ServesCard from "./ServesCard";

const serversData = [
  {
    id: 1,
    img: "/serves/serves-1.svg",
    titleKey: "rentalCarsTitle",
    descKey: "rentalCarsDesc",
  },
  {
    id: 2,
    img: "/serves/serves-2.svg",
    titleKey: "saveTimeTitle",
    descKey: "saveTimeDesc",
  },
  {
    id: 3,
    img: "/serves/serves-3.svg",
    titleKey: "wideSelectionTitle",
    descKey: "wideSelectionDesc",
  },
  {
    id: 4,
    img: "/serves/serves-4.svg",
    titleKey: "personalizedDealsTitle",
    descKey: "personalizedDealsDesc",
  },
];

type Props = {
  price?: string;
};

const RentServesYou = ({ price }: Props) => {
  const format = useFormatter();
  const t = useTranslations("LP3");

  return (
    <section id="rent80-serves-you" className="px-[13px]">
      <div className="max-w-7xl mx-auto mt-[22px] mb-[18px] lg:my-[60px]">
        <h2 className="font-extrabold text-lg lg:text-[30px] text-my-dark-blue">
          {t("servesTitle")}
        </h2>
        <div className="md:grid grid-cols-2 lg:grid-cols-4 gap-6 rounded-[16px] md:rounded-none overflow-clip mt-[18px] lg:mt-10">
          {serversData.map(({ id, img, titleKey, descKey }) =>
            <ServesCard
              id={id}
              key={id}
              title={t(titleKey, { price: format.number(Number(price) || 2.99, { currency: "USD", style: "currency" }) })}
              desc={t(descKey)}
              img={img}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default RentServesYou;
